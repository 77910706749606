/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import ImagesUrl from "../../utils/constants/images";
import TempLayout from "../../components/UI/tempLayout";
import ScreenLayout from "../../components/UI/screenLayout";
import ENDPOINTS from "../../services/endPoints";

import QUERY_KEYS from "../../services/queryKeys";
import usePost from "../../hooks/usePost";
import { toast } from "react-toastify";
import { uniqueIdGenerator } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import { Rating } from "react-simple-star-rating";
import CustomModal from "../../components/customModal/customModal";
import useGet from "../../hooks/useGet";

const Actions = ({ props }) => {
  const {
    data,
    templateId,
    handleEditForm,
    editTemplate,
    refetchStepDetail,
    dataLoading,
    stepId,
  } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [reviewModal, setReviewModal] = useState(false);
  const fileInput = useRef(null);
  const [actionReview, setActionReview] = useState(0);

  const [form1, setForm1] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description:
      "Develop this skill by taking action. One step at a time. Pick one and give it a go!",
    buttonText: "Tap to continue",
  });
  const [form2, setForm2] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description:
      "You can do it now or save for later. Your saved actions can be found on your Home page.",
    buttonText: "Tap to continue",
  });
  const [form3, setForm3] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description:
      "You get out what you put in, and you learn the best by doing. The actions will get more challenging later on. Ready?",
    buttonText: "Let's go",
  });
  const [form4, setForm4] = useState({
    formId: uniqueIdGenerator(),
    formType: "thoughts",
    question:
      "Select the action(s) you want to work on from the list below. You will be able to find and track these from the Actions list on the Home page.",

    options: [
      {
        title: "Challenge your thoughts",
        description:
          "Write down your top 3 limiting beliefs that hold you back. Next, jot down evidence to the contrary, times you've proved that belief wrong. Lastly, write a new version of the belief that demonstrates some self-compassion.",
      },
      {
        title: "Pump it up",
        description:
          "Create a playlist of pump up songs to play before something you're nervous about like a presentation or a negotiation. Include songs with a strong beat like Eye of the Tiger (Survivor), All I Do Is Win (DJ Khaled), or Confident (Demi Lovato).",
      },
    ],
    buttonText: "Save to my Actions",
  });
  const [finalForm, setFinalForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "final square",
    image: {
      form_unique_id: "",
      added_image: "",
    },
    title: "Great Choice!",
    description:
      "Your selection has been successfully added to your Actions list.",
    buttonText: "Action",
  });
  const [scenarioActionsData, setActionsData] = useState([
    {
      name: data?.name ?? "Reality Check",
      form_json: [form1, form2, form3, form4, finalForm],
    },
  ]);
  const nav = useNavigate();
  const { mutateAsync: addImagesMutateAsync, isLoading: isLoadingAddImages } =
    usePost(ENDPOINTS.TEMPLATE_IMAGES, QUERY_KEYS.TEMPLATE_IMAGES_QUERY_KEY);
  const { data: actionRatingViewData, refetch: refetchActionReview } = useGet(
    ENDPOINTS.ACTION_RATINGS,
    QUERY_KEYS.ACTION_RATINGS_QUERY_KEY,
    {
      step_id: stepId,
    }
  );

  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );
  const handleFileInput = (target) => {
    setSelectedFile(target.files[0]);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const handleOptionForm = (event, index, key) => {
    setForm4((prevForm) => {
      prevForm.options[index][key] = event.target.innerText;
      return { ...prevForm };
    });
  };
  const handelApply = (imageResponse) => {
    const formData = [
      {
        name: data?.name ?? "Actions",
        form_json: [
          form1,
          form2,
          form3,
          form4,
          {
            ...finalForm,
            image: imageResponse?.data ?? finalForm.image,
          },
        ],
      },
    ];
    setActionsData(formData);
    if (imageResponse !== null) {
      setFinalForm({
        ...finalForm,
        image: imageResponse?.data,
      });
    }
    uploadFormJson(formData);
  };
  const handelReviewModal = (id) => {
    setActionReview(id);
    setReviewModal(!reviewModal);
  };
  const uploadFormJson = async (formData) => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        refetchStepDetail();
        toast.success(response?.message);
        handleEditForm();
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
    //add upload form api
  };
  const handleUpload = async (callback) => {
    if (selectedFile == null) {
      callback(null);
    } else {
      const imageId = finalForm?.image?.form_unique_id ?? "";
      const formData = new FormData();
      formData.append("step_id", stepId);
      formData.append("sub_step_id", data?._id);
      formData.append("template_id", templateId);
      formData.append("form_unique_id", imageId);
      formData.append("image", selectedFile);
      try {
        const response = await addImagesMutateAsync(formData);

        if (response.statusCode === 200) {
          callback(response);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };
  const ActionReviewresult = actionRatingViewData?.data?.rating_list?.filter(
    (item) => item?.question === form4.options[actionReview]?.title
  );

  useEffect(() => {
    if (data?.template_id == 4) {
      const forms = data?.form_json;
      if (forms && forms?.length !== 0) {
        setForm1(forms[0]?.form_json[0]);
        setForm2(forms[0]?.form_json[1]);
        setForm3(forms[0]?.form_json[2]);
        setForm4(forms[0]?.form_json[3]);
        setFinalForm(forms[0]?.form_json[4]);
      } else {
        handleEditForm(true);
      }
    }
  }, [, refetchStepDetail]);
  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handleUpload(handelApply)}
      loading={isLoadingAddTemplate || isLoadingAddImages}
      dataLoading={dataLoading}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name={data.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form1.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm1({
                            ...form1,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form1.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>

                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm2({
                                ...form2,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form2.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm2({
                            ...form2,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form2.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>

                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm3({
                                ...form3,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form3.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm3({
                            ...form3,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form3.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data.name}>
                  <div
                    className="checkbox-wrapper"
                    style={{ maxHeight: "395px", marginTop: "0px" }}
                  >
                    <div className="checkbox-list">
                      <div className="sm-content">
                        <p
                          contentEditable={editTemplate}
                          onBlur={(e) => {
                            setForm4({
                              ...form4,
                              question: e.target.innerText,
                            });
                          }}
                        >
                          {form4?.question}
                        </p>
                      </div>
                      <div className="screen-body px-35">
                        <div className="checkbox-wrapper check-alignment-top">
                          {form4?.options?.map((item, index) => {
                            return (
                              <div className="checkbox-list">
                                <input
                                  type="radio"
                                  className="d-none"
                                  name="listradio2"
                                  // id={`checkbox_0${index}`}
                                />
                                <label
                                  htmlFor={`checkbox_0${index}`}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="d-flex w-100 flex-end">
                                    <div className="d-flex gap-3 align-items-center mt-4">
                                      <span
                                        style={{ width: "180px" }}
                                        className="radio-heading m-0"
                                        contentEditable={editTemplate}
                                        onBlur={(e) =>
                                          handleOptionForm(e, index, "title")
                                        }
                                      >
                                        {item?.title}
                                      </span>
                                      <button
                                        className="review_btn "
                                        onClick={() => handelReviewModal(index)}
                                      >
                                        Reviews
                                      </button>
                                    </div>
                                  </div>

                                  <span
                                    className="check-box-text"
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleOptionForm(e, index, "description")
                                    }
                                  >
                                    {item?.description}
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <button
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm4({
                            ...form4,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form4.buttonText}
                      </button>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35 item-center">
                    <div className="upload-img-blk">
                      <img
                        className="preview-img"
                        src={
                          selectedFile === null
                            ? finalForm?.image?.added_image === ""
                              ? ImagesUrl.shape_image
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${finalForm?.image?.added_image}`
                            : URL.createObjectURL(selectedFile)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput}
                          onChange={({ target }) => handleFileInput(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "300px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content d-flex justify-content-center w-100 ">
                          <p
                            className="ls-font ft-22 lht-32 pb-2 "
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                title: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm?.title}
                          </p>
                        </div>
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setFinalForm({
                            ...finalForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {finalForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <CustomModal
                  open={reviewModal}
                  customClass="modal-xl"
                  title="Reviews"
                  cancelTitle="Close"
                  actionButtonClass="bg-danger"
                  isLoading={false}
                  onClose={() => handelReviewModal()}
                  actionButtonNotRequire={true}
                >
                  {ActionReviewresult?.length > 0 ? (
                    <div className="d-flex justify-content-between m-2">
                      <div className="d-flex gap-1 flex-end">
                        <h6 className="m-0">
                          {form4.options[actionReview]?.title}
                        </h6>
                      </div>

                      <div className="d-flex gap-1 flex-end">
                        <h6 className="m-0">Total :</h6>
                        <p className="m-0">{ActionReviewresult?.length}</p>
                      </div>
                    </div>
                  ) : null}

                  <div className="review_container">
                    {ActionReviewresult?.length > 0 ? (
                      ActionReviewresult?.map((item, i) => {
                        const formatDate = (dateString) => {
                          const options = {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          };
                          const date = new Date(dateString);
                          return date.toLocaleDateString("en-GB", options);
                        };

                        return (
                          <div
                            className="d-flex align-items-center justify-content-between border border-success rounded p-2"
                            key={i}
                          >
                            <div className="d-flex gap-3 align-items-center justify-content-center">
                              <img
                                className="rounded-circle"
                                height="25px"
                                width="25px"
                                src={ImagesUrl.empty_image}
                                alt="..."
                              />
                              <div className="content_div">
                                <h6 style={{ fontSize: "15px", margin: "0px" }}>
                                  {item?.question}
                                </h6>
                                <p>
                                  {item.first_name} {item.last_name}
                                </p>
                                <p>{formatDate(item?.created_at)}</p>
                              </div>
                            </div>
                            <div>
                              <Rating
                                initialValue={item?.rating}
                                size="20"
                                readonly={true}
                                allowHover={false}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center d-flex justify-content-center align-items-center h-100">
                        <p>No ratings available.</p>
                      </div>
                    )}
                  </div>
                </CustomModal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default Actions;
