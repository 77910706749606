/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import ImagesUrl from "../../utils/constants/images";
import ScreenLayout from "../../components/UI/screenLayout";
import TempLayout from "../../components/UI/tempLayout";
import RangeForm from "../../components/UI/rangeForm";
import { toast } from "react-toastify";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { FadeLoader } from "react-spinners";
import { uniqueIdGenerator } from "../../utils/helpers";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";

const BehavioralTendencies = ({ props }) => {
  const {
    data,
    templateId,
    handleEditForm,
    editTemplate,
    refetchStepDetail,
    dataLoading,
    stepId,
  } = props;

  const [form1, setForm1] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description:
      "Encounter a series of thought-provoking statements related to your behavioral tendencies.",
    buttonText: "Tap to continue",
  });
  const [form2, setForm2] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description: "Begin the journey of self-discovery.",
    buttonText: "Tap to continue",
  });
  const [form3, setForm3] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description:
      "Read each statement and indicate the extent to which it resonates with you.",
    buttonText: "Let's go",
  });
  const [finalForm, setFinalForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "final",
    image: {
      form_unique_id: "",
      added_image: "",
    },
    description: "Congratulations on completing this Assessment!",
    returnHomeButtonText: "Return to Journey",
    buttonText: "Continue learning",
  });
  const [subForm, setSubForm] = useState([
    {
      formId: uniqueIdGenerator(),
      formType: "range",
      rang_left_title: "Nope, not me at all",
      range_right_title: "Yep, that’s totally me",
      image: {
        form_unique_id: "",
        added_image: "",
      },
      description:
        "I’m never happy with “good enough”; I need things to be perfect.",
      agree:
        "Perfectionism can be simultaneously your superpower and your kryptonite. Your high standards will hold you back when you need to be more efficient. Define ‘good enough’ and learn to let go when you reach it.",
      neutral:
        "That’s fantastic! You should be really proud of this. Use it as a strength to build other skills. Make sure you’re learning as much as possible out of every mistake.",
      disagree:
        "Rejection really sucks, whether it’s a hot date or a higher salary, but you already know this is something you need to work on to get ahead. In this journey, you’ll learn how to build the confidence to ask for what you want. ",
      buttonTitle: "Tap to continue",
    },
  ]);
  const [resultStatementForm, setResultStatementForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "statement",
    title: "Here’s what our members are saying",
    description: "based on all users’ responses",
    buttonTitle: "Tap to continue",
    statements: ["struggle with moving on when something is “good enough."],
  });

  const [formData, setFormData] = useState([
    {
      name: data?.name ?? "Assessment",
      form_json: [
        form1,
        form2,
        form3,
        {
          subForms: subForm,
        },
        resultStatementForm,
        finalForm,
      ],
    },
  ]);

  const [selectedFinalFile, setSelectedFinalFile] = useState(null);
  const nav = useNavigate();
  const fileInput = useRef(null);
  const handleFileInputMultiple = (index, file) => {
    setSubForm((prevSubForm) => {
      const newSubForm = [...prevSubForm];
      newSubForm[index].image.added_image = file;
      return newSubForm;
    });
  };
  const handleImageUrl = (index, url) => {
    const newSubForm = [...subForm];
    newSubForm[index].image = url;
    setSubForm(newSubForm);
  };

  const handleFileInput = (target) => {
    setSelectedFinalFile(target.files[0]);
    target.value = "";
  };

  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const addNewStatement = () => {
    setSubForm([
      ...subForm,
      {
        formId: uniqueIdGenerator(),
        formType: "range",
        rang_left_title: "Nope, not me at all",
        range_right_title: "Yep, that’s totally me",
        image: {
          form_unique_id: "",
          added_image: "",
        },
        description:
          "I’m never happy with “good enough”; I need things to be perfect.",
        agree:
          "Perfectionism can be simultaneously your superpower and your kryptonite. Your high standards will hold you back when you need to be more efficient. Define ‘good enough’ and learn to let go when you reach it.",
        neutral:
          "That’s fantastic! You should be really proud of this. Use it as a strength to build other skills. Make sure you’re learning as much as possible out of every mistake.",
        disagree:
          "Rejection really sucks, whether it’s a hot date or a higher salary, but you already know this is something you need to work on to get ahead. In this journey, you’ll learn how to build the confidence to ask for what you want. ",
        buttonTitle: "Tap to continue",
      },
    ]);

    let newStatements = resultStatementForm.statements;
    newStatements.push(
      "struggle with moving on when something is “good enough."
    );
    setResultStatementForm({
      ...resultStatementForm,
      statements: newStatements,
    });
  };

  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
    isSuccess: isSuccessAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );
  const { mutateAsync: addImagesMutateAsync, isLoading: isLoadingAddImages } =
    usePost(ENDPOINTS.TEMPLATE_IMAGES, QUERY_KEYS.TEMPLATE_IMAGES_QUERY_KEY);
  const removeStatement = (index) => {
    const newSubForm = [...subForm];
    newSubForm.splice(index, 1);
    setSubForm(newSubForm);
    setResultStatementForm((prevForm) => {
      const newStatements = [...prevForm.statements];
      newStatements.splice(index, 1);
      return { ...prevForm, statements: newStatements };
    });
  };
  const handleSubForm = (event, index, key) => {
    setSubForm((prevSubForm) => {
      prevSubForm[index][key] = event.target.innerText;
      return [...prevSubForm];
    });
  };

  const handleUpload = async (callback) => {
    if (selectedFinalFile == null) {
      callback(null);
    } else {
      const imageUniqueId = finalForm?.image?.form_unique_id ?? "";
      const formData = new FormData();
      formData.append("step_id", stepId);
      formData.append("sub_step_id", data?._id);
      formData.append("template_id", templateId);
      formData.append("form_unique_id", imageUniqueId);
      formData.append("image", selectedFinalFile);

      try {
        const response = await addImagesMutateAsync(formData);
        if (response.statusCode === 200) {
          callback(response);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handelApply = async (imageResponse) => {
    const formData = [
      {
        name: data?.name ?? "Assessment",
        form_json: [
          form1,
          form2,
          form3,
          {
            subForms: subForm,
          },
          resultStatementForm,
          {
            ...finalForm,
            image: imageResponse?.data ?? finalForm.image,
          },
        ],
      },
    ];

    setFormData(formData);
    if (imageResponse !== null) {
      setFinalForm({
        ...finalForm,
        image: imageResponse?.data,
      });
    }

    uploadFormJson(formData);
  };

  const handleUploadRangeImages = async (callback) => {
    const promises = [];
    subForm.forEach((item, index) => {
      const imageId = item?.image?.form_unique_id ?? "";
      let image = item?.image?.added_image;
      if (image instanceof File) {
        const formData = new FormData();
        formData.append("step_id", stepId);
        formData.append("sub_step_id", data?._id);
        formData.append("template_id", templateId);
        formData.append("form_unique_id", imageId);
        formData.append("image", image);
        promises.push(
          addImagesMutateAsync(formData).then((response) => ({
            index,
            response,
          }))
        );
      }
    });

    try {
      const responses = await Promise.all(promises);
      const allSuccessful = responses.every(
        (response) => response.response.statusCode === 200
      );
      if (allSuccessful) {
        responses.forEach((response) =>
          handleImageUrl(response.index, response.response.data)
        );
        callback(handelApply);
      } else {
        toast.error("Some images failed to upload");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const uploadFormJson = async (formData) => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        refetchStepDetail();
        toast.success(response?.message);
        handleEditForm();
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
  };
  const handleUpdateStatement = (event, index) => {
    setResultStatementForm((prevForm) => {
      prevForm.statements[index] = event.target.innerText;
      return { ...prevForm };
    });
  };

  useEffect(() => {
    if (data?.template_id == 2) {
      const forms = data?.form_json;

      if (forms && forms?.length !== 0) {
        setForm1(forms[0]?.form_json[0]);
        setForm2(forms[0]?.form_json[1]);
        setForm3(forms[0]?.form_json[2]);
        setSubForm(forms[0]?.form_json[3]?.subForms);
        setResultStatementForm(forms[0]?.form_json[4]);
        setFinalForm(forms[0]?.form_json[5]);
      } else {
        handleEditForm(true);
      }
    }
  }, [, refetchStepDetail]);

  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handleUploadRangeImages(handleUpload)}
      loading={isLoadingAddImages || isLoadingAddTemplate}
      dataLoading={dataLoading}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "395px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form1.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm1({
                            ...form1,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form1.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "395px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm2({
                                ...form2,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form2?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm2({
                            ...form2,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form2?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{
                          maxHeight: "395px",
                          marginTop: "0px",
                        }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm3({
                                ...form3,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form3?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm3({
                            ...form3,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form3?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>

                {subForm?.map((item, index, array) => {
                  return (
                    <RangeForm
                      isLoadingAddImages={isLoadingAddImages}
                      index={index}
                      removeStatement={removeStatement}
                      editTemplate={editTemplate}
                      item={item}
                      handleSubForm={handleSubForm}
                      subForm={subForm}
                      handleFileInputMultiple={handleFileInputMultiple}
                      array={array}
                    />
                  );
                })}
                {editTemplate ? (
                  <div className="screen-layout border border-success">
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                      <h3
                        className="cursor-pointer"
                        onClick={editTemplate ? addNewStatement : null}
                      >
                        <i class="fa-solid fa-square-plus"></i> Forms
                      </h3>
                    </div>
                  </div>
                ) : null}
                <ScreenLayout name={data?.name} notHeaderRequire={true}>
                  <div
                    className="checkbox-wrapper"
                    style={{
                      maxHeight: "467px",
                      minHeight: "467px",
                      marginTop: "0px",
                    }}
                  >
                    <div className="checkbox-list">
                      <div className="screen-header">
                        <h2
                          className="w-100 text-center"
                          contentEditable={editTemplate}
                          onBlur={(e) => {
                            setResultStatementForm({
                              ...resultStatementForm,
                              title: e.target.innerText,
                            });
                          }}
                        >
                          {resultStatementForm.title}
                        </h2>
                      </div>
                      <div
                        className="screen-body"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="screen-content">
                          <ul className="list-check">
                            {resultStatementForm.statements.map((item, i) => {
                              return (
                                <li key={i}>
                                  XX%
                                  <span
                                    style={{ marginLeft: "10px" }}
                                    contentEditable={editTemplate}
                                    onBlur={(e) => handleUpdateStatement(e, i)}
                                  >
                                    {item}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="link-blk">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setResultStatementForm({
                                ...resultStatementForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {resultStatementForm.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setResultStatementForm({
                            ...resultStatementForm,
                            buttonTitle: e.target.innerText,
                          });
                        }}
                      >
                        {resultStatementForm.buttonTitle}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>

                <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35">
                    <div className="upload-img-blk">
                      <img
                        className="preview-img"
                        src={
                          selectedFinalFile === null
                            ? finalForm?.image?.added_image === ""
                              ? ImagesUrl.shape_image
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${finalForm?.image?.added_image}`
                            : URL.createObjectURL(selectedFinalFile)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput}
                          onChange={({ target }) => handleFileInput(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>

                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "267px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            className="ls-font ft-22 lht-32"
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div
                      className="btn-text-style py-0"
                      contentEditable={editTemplate}
                      onBlur={(e) => {
                        setFinalForm({
                          ...finalForm,
                          returnHomeButtonText: e.target.innerText,
                        });
                      }}
                    >
                      {finalForm?.returnHomeButtonText}
                    </div>
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setFinalForm({
                            ...finalForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {finalForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default BehavioralTendencies;
