import * as Yup from "yup";

const addJourneyInitialValues = {
  journeyName: "",
  journeyDescription: "",
  journeyImage: "",
  journeyBackgroundImg: "",
};
const addJourneyValidationSchema = Yup.object().shape({
  journeyName: Yup.string().trim().required("Name is required*"),
  journeyDescription: Yup.string().trim().required("Description  is required*"),
  journeyImage: Yup.string().trim().required("Image is required*"),
  journeyBackgroundImg: Yup.string().trim().required("Image is required*"),
});
export { addJourneyInitialValues, addJourneyValidationSchema };
